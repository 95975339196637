import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import { Icon } from '../Icon';
import Element, { ElementProps } from './Element';

export interface CheckboxProps extends ElementProps {
  checkBoxClasses?: {
    checkBoxLabel?: string;
  };
}

const Checkbox: React.FC<CheckboxProps> = props => {
  const { checkBoxClasses, name, disabled, label, required } = props;
  const { checkBoxLabel = 'h-5' } = checkBoxClasses || {};
  const [field, , helpers] = useField<boolean>(name);
  return (
    <div className="flex">
      <div className="relative align-middle">
        <div
          className={classNames(
            'leading-6 flex h-5 w-5 border absolute pointer-events-none',
            {
              'border-gray-500 bg-gray-400': field.value && disabled,
              'border-bright-blue-600 bg-bright-blue-600':
                field.value && !disabled,
              'border-gray-300': !field.value,
              'bg-gray-100': !field.value && disabled,
              'bg-white': !field.value && !disabled,
            }
          )}
        >
          {field.value && <Icon name="ui/check" size="16" color="white" />}
        </div>
        <input
          data-testid={name}
          id={name}
          type="checkbox"
          className={classNames(
            'h-5 w-5 mr-3 focus-visible:outline-0 focus:shadow-none'
          )}
          {...field}
          name={name}
          onChange={evt => helpers.setValue(evt.target.checked)}
          value="true"
          disabled={disabled}
          checked={Boolean(field.value)}
        />
      </div>
      <Element {...props} labelHidden>
        <p aria-hidden="true" className={classNames(checkBoxLabel)}>
          {label}
          {required ? '*' : null}
        </p>
      </Element>
    </div>
  );
};

export default Checkbox;
